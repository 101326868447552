body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ribbon {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}

.ribbon span {
  font-size: 0.8rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  background: #79a70a;
  position: absolute;
  top: 15px;
  right: -29px;
}

.blink {
  animation: 2s linear 0s 10 condemned_blink_effect;
}

@keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}


a .shadow-sm:hover {
  box-shadow: 0 .125rem 1.25rem rgba(0, 0, 0, .375) !important
}

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  border-radius: 21px;
  transform: rotate(30deg);
}

.product-device-2 {

  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  opacity: 0.4;
}
@media (max-width: 1199.98px) {
  .product-device {
    right: -5%;
    bottom: -25%;
    width: 300px;
    border-radius: 21px;
    transform: rotate(30deg);
  }
  
  .product-device-2 {
  
    top: -25%;
    right: auto;
    bottom: 0;
    left: -10%;
    opacity: 0.4;
  }
}

#ct{ border:2px solid #212529;margin: 100px auto;text-align:center;position:relative;padding:15px;}
#ct span{
  background:#F8F9FA;
  color:#212529;
  padding:0 10px;
  position:relative; 
  top:-49px;
}
#ct blockquote {
  margin-top:-3rem
}
#ct .corner{height:40px;
  width:40px;
  border-radius:50%;
  border:2px solid #fff;
  transform:rotate(-45deg);  
  position:absolute;
  background:#F8F9FA;
}

#ct #left_top{
  top:-22px;
  left:-22px;
  border-color:transparent transparent #212529 transparent;
}
#ct #right_top{
  top:-22px;
  right:-22px;
  border-color:transparent transparent transparent #212529;
}
#ct #left_bottom{
  bottom:-22px;
  left:-22px;
  border-color:transparent #212529 transparent transparent ;
}
#ct #right_bottom{
  bottom:-22px;
  right:-22px;
  border-color:#212529 transparent transparent transparent;
}
#ct p{padding-top:13px;font-size:18px}


@import url(https://fonts.googleapis.com/css?family=Dr+Sugiyama);

.mb-wrap {
  margin: 20px auto;
  padding: 20px;
  position: relative; 
}

.mb-wrap p{
  margin: 0;
  padding: 0;
}

.mb-wrap blockquote {
  margin: 0;
  padding: 0;
  position: relative;
}

.mb-wrap cite {
  font-style: normal;
}

.example5:before, .example5:after {
  content: "";
    position: absolute;
    z-index: -5;
    -moz-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
    bottom: 10px;
    height: 20%;
    left: 10px;
    max-height: 100px;
    max-width: 700px;
    width: 30%;
}
.example5:after {
    -moz-transform: rotate(3deg);
  -webkit-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
    left: auto;
    right: 10px;
}

.example5 blockquote{
  padding: 40px;
  text-align: center;
}

.example5 blockquote:before{
  color: rgba(0,0,0,0.2);
  position: absolute;
  content: "“";
  font-size: 150px;
  line-height: 130px;
  font-style: italic;
  top: 0px;
  right: 20px;
  font-family: Cambria, Georgia, serif;
}

.example5 blockquote p {
  color: #333;
  font-family: 'Abril Fatface', sans-serif;
  font-size: 24px;
  text-shadow: 1px 1px 1px #000;
}
.example5 .mb-attribution {
  padding: 20px;
}

.example5 .mb-author {
  font-size: 24px;
  font-family: 'Dr Sugiyama', cursive;
  -moz-transform: rotate(-4deg);
  color: #070d5f;
}
.example5 cite {
  color: #999;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
}
